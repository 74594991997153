<template lang="pug">
  .others
    v-container
      div Others

</template>

<script>

export default {
  name: 'Others',
  data: () => ({ 
  }),
  mounted () {
  },
  methods: {
  }
}
</script>
